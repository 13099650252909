<template lang="pug">
     v-container
        template(v-if="productItems && productItems.length>0")
            v-card(v-for="product in productItems" :key="product.id" class="mx-auto mb-1" max-width="400")
                v-card-text.px-1.py-0
                    v-row
                        v-col.py-1 {{ product.name }} 
                            div
                                b {{ product.barcode }}
                        v-col(cols="2")
                            v-btn#likecb(x-small dark outlined color="#808080"  @click.native="changePresence(product)")
                                template(v-if="product.reportData && product.reportData.presense")
                                    v-icon(v-if="product.reportData.presense == 'V'") mdi-check
                                    v-icon(v-if="product.reportData.presense == 'OUT'") mdi-minus
                        v-col(cols="2")
                            v-btn(x-small fab dark color="blue" @click.native="showDialog(product)")
                                v-icon mdi-plus
        template(v-else-if="productItems && productItems.length == 0")
            v-card(class="mx-auto mb-1" max-width="400")
                v-card-text
                    v-icon(color="red") mdi-alert
                    span Нет продуктов в выбранной категории

        v-card(v-if="loading" class="mx-auto" flat max-width="50")
            v-progress-circular(:size="50" color="primary" indeterminate )

        v-card(v-if="error" class="mx-auto" max-width="400")
            v-card-title Что-то пошло не по плану
                v-spacer
                v-icon(color="red") mdi-alert
            v-card-subtitle {{ error }}


        v-dialog(v-if="selectedItem" v-model="dialog" max-width="400" fullscreen transition="dialog-bottom-transition")
            v-card
                v-toolbar(dark color="deep-purple accent-4")
                    v-btn(icon dark @click="dialog = false")
                        v-icon mdi-close
                    v-toolbar-title {{ selectedItem.name }}

                template(v-if="productError && !productLoading")
                    v-card-title Что-то пошло не по плану
                        v-spacer
                        v-icon(color="red") mdi-alert
                    v-card-subtitle {{ productError }}

                v-card-text
                    div.w50(v-if="productLoading" class="mx-auto")
                        v-progress-circular(:size="50" color="primary" indeterminate)
                    template(v-if="selectedItemReports.length > 0")
                        v-form(v-on:submit.prevent="submit" ref="form" lazy-validation v-model="valid")
                            template(v-for="repItem in selectedItemReports")
                                v-divider
                                template(v-for="field in repItem.reportFieldWithData")

                                    template(v-if="field.fieldType.name == 'STRING'")
                                        v-text-field(v-model="field.value" :label="field.name" :rules="validateField(field)" required)
                                            template(v-if="repItem.tradeAction" v-slot:append-outer)
                                                v-icon(color="yellow" :title="'Поле акции: '+repItem.tradeAction.name") mdi-star

                                    template(v-if="field.fieldType.name == 'INT'")
                                        v-text-field(v-model="field.value" :label="field.name" :rules="validateField(field)" required)
                                            template(v-if="repItem.tradeAction" v-slot:append-outer)
                                                v-icon(color="yellow" :title="'Поле акции: '+repItem.tradeAction.name") mdi-star

                                    template(v-if="field.fieldType.name == 'FLOAT'")
                                        v-text-field(v-model="field.value" :label="field.name" :rules="validateField(field)" required)
                                            template(v-if="repItem.tradeAction" v-slot:append-outer)
                                                v-icon(color="yellow" :title="'Поле акции: '+repItem.tradeAction.name") mdi-star

                                    template(v-else-if="field.fieldType.name == 'SELECT'")
                                        v-select(v-model="field.value" :label="field.name" :items="field.oprions" item-text="value" item-value="value" :rules="validateField(field)" required)
                                            template(v-if="repItem.tradeAction" v-slot:append-outer)
                                                v-icon(color="yellow" :title="'Поле акции: '+repItem.tradeAction.name") mdi-star

                            v-divider
                            v-file-input(v-model="photos" multiple accept="image/*" label="Загрузить фото" @change="fchange()")
                            template(v-if="photosLoading.loading")
                                v-progress-linear(v-model="photosLoading.loaded" :buffer-value="photosLoading.total")
                                div Загружено {{photosLoading.loaded}} из {{photosLoading.total}} байт, {{photosLoading.progress}}%
                            template(v-else-if="!photosLoading.loading && photosLoading.error") {{ photosLoading.error }}
                            v-row
                                v-col(v-for="img in selectedItemReports[0].photo" :key="img.uuid" class="d-flex child-flex" cols="4")
                                    v-img(alt="img" :src="'http://localhost:8080/api/report/reportphoto/'+img.uuid")

                                v-col(v-for="(img,index) in selectedItemRPhotos" :key="index" class="d-flex child-flex" cols="4")
                                    v-img(alt="img" :src="'http://localhost:8080/api/tempfiles/'+img.uuid+'.'+img.extension")

                            v-divider
                            v-text-field(v-model="selectedItemReports[0].comment" label="Комментарий")

                            v-card-actions
                                v-btn(type="submit") Отправить
                                v-btn(@click="hideDialog()") Отмена

        template(v-if="productItems && productItems.length > 0")
            div.emptyspace

            v-btn(fixed bottom left fab color="#ff4081" @click="sheet = !sheet")
                v-icon(color="#fff") mdi-arrow-up

            v-bottom-sheet(v-model="sheet" persistent)
                v-sheet(height="250px")
                    v-container
                        div.photos
                            template(v-if="photosLoading.loading")
                                v-progress-linear(v-model="photosLoading.loaded" :buffer-value="photosLoading.total")
                                div Загружено {{photosLoading.loaded}} из {{photosLoading.total}} байт, {{photosLoading.progress}}%
                            template(v-else-if="!photosLoading.loading && photosLoading.error") {{ photosLoading.error }}
                            v-row(align="center" justify="space-around" style="flex-wrap: nowrap;")
                                v-img(v-for="img in prereport.reportPhoto" :key="img.id" max-height="100" max-width="200" :src="apiPath+'report/reportphoto/'+img.uuid")    
                                v-img(v-for="img in prereport.tempPhotos" :key="img.id" max-height="100" max-width="200" :src="apiPath+'tempfiles/'+img.uuid+'.'+img.extension")    

                        div.comment
                            v-text-field(v-model="prereport.comment" label="Комментарий к отчёту")
                        v-row(align="center" justify="space-around")
                            label#repfileslable(for="repfiles" class="v-btn v-btn--contained v-btn--fab v-btn--round theme--light v-size--default")
                                v-icon(color="#fff") mdi-camera
                            v-file-input#repfiles(v-model="prereport.photos" multiple accept="image/*" @change="repFileChange()")
                            v-btn(dark color="#ff4081" :loading="prereport.sending" @click="sendPrereport()") Сохранить
                            v-btn(fab color="#ff4081" @click="sheet = !sheet")
                                v-icon(color="#fff") mdi-arrow-down

            v-dialog(v-model="sendingReport.dialog" max-width="400")
                v-card
                    v-card-title {{ sendingReport.title }}
                        v-spacer
                        v-icon(v-if="sendingReport.noreportdata.length > 0" color="red") mdi-alert
                    v-card-subtitle {{ sendingReport.subtitle }}

                    v-card-text
                        div.w50(v-if="sendingReport.sending" class="mx-auto")
                            v-progress-circular(:size="50" color="primary" indeterminate)
                        div(v-if="sendingReport.noreportdata.length > 0")
                            v-row
                                v-col Категория
                                v-col Продукт
                            v-row(v-for="(p,i) in sendingReport.noreportdata" :key="i") 
                                v-col {{ p.category }}
                                v-col {{ p.product }}
                

            v-btn(:disabled="!report ? true : false" fixed bottom right fab color="#ff4081" title="Отправить отчёт"  @click="sendReport()")
                v-icon(color="#fff") mdi-send

</template>


<script>
    import { config } from '@/_helpers'
    import { categoryService, productService, reportService, fileService } from '@/_services'
    import { mapState, mapMutations } from "vuex";

    export default {
        components:{
        },
        data: () => ({
            loading:false,
            error:null,
            dialog:false,
            sheet:false,

            productItems:null,

            selectedItem:null,
            selectedItemReports:[],
            selectedItemRPhotos:[],

            report:null,

            prereport:{
                tradePointId:null,
                comment:'',
                reportPhoto:[],
                tempPhotos:[],
                photos:[],
                sending:false
            },

            productLoading:false,
            productError:null,

            valid:true,


            photos:[],
            photosLoading:{
                error:null,
                loading:false,
                loaded:0,
                total:0
            },

            sendingReport:{
                dialog:false,
                sending:false,
                title:'',
                subtitle:'',
                noreportdata:[]
            }
        }),

        mounted() {
            this.prereport.tradePointId = this.$route.params.pid
            this.loadReport()
            if (this.$route.params.cid == 'a') this.setSelectedCategory({id:'a',name:'Акция',type:'action'})
            if (!this.selectedCategory) this.loadCategory()
            else {
                this.setAppBarTitle(this.selectedCategory.name)
            }
            this.loadProducts()
        },
        destroyed() {
            this.setSelectedCategory(null)
        },

        computed: {
            ...mapState(['appBarTitle']),
            ...mapState("category", ["selectedCategory"]),
            apiPath(){
                return config.apiUrl
            }
        },
        methods: {
            ...mapMutations(['setAppBarTitle']),
            ...mapMutations("category", ["setSelectedCategory"]),

            loadCategory(){
                this.setAppBarTitle('loading')
                categoryService.loadCategory(this.$route.params.cid)
                    .then(data => {
                        this.setSelectedCategory(data)
                        this.setAppBarTitle(data.name)
                    })
                    .catch(error => {
                        this.setSelectedCategory(null)
                        this.setAppBarTitle(error)
                    })
            },

            loadReport(){
                reportService.getReportByPointId(this.$route.params.pid)
                    .then(data => {
                        this.report = data
                        if (this.report) {
                            this.prereport.photos = []
                            this.prereport.tempPhotos = []
                            this.prereport.comment = data.comment
                            this.prereport.reportPhoto = data.reportPhoto
                        }
                    })
                    .catch(error => {

                    })
            },

            loadProducts(){
                this.loading = true
                if (this.$route.params.cid == 'a'){
                    productService.loadPointActionProducts(this.$route.params.pid)
                        .then(data => {
                            this.productItems = data
                            this.loading = false
                        })
                        .catch(error => {
                            this.error = error
                            this.loading = false
                        })
                } else {
                    productService.loadPointProducts(this.$route.params.pid,this.$route.params.cid)
                        .then(data => {
                            this.productItems = data
                            this.loading = false
                        })
                        .catch(error => {
                            this.error = error
                            this.loading = false
                        })
                }
            },

            changePresence(product){
                let presence = null
                let pd = product.reportData
                if (!pd || !pd.presense || pd.presense == 'OOS') presence = 'V'
                else if (pd.presense == 'V') presence = 'OUT'
                else if (pd.presense == 'OUT') presence = 'OOS'

                if (!product.reportData) this.$set(product,'reportData',{})
                product.reportData.presense = presence

                let data = {
                        tradePointId: this.$route.params.pid,
                        productId: product.id,
                        presense: presence
                    }
                reportService.sendReportData(data)
                    .then(data => {
                        product.reportData = data
                        if (!this.report) this.loadReport()
                    })
                    .catch(error => {
                        product.reportData.presense = null
                    })
            },

            showDialog(product){
                this.selectedItem = null
                this.selectedItemReports = []
                this.selectedItemRPhotos = []
                this.selectedItem = product
                this.dialog = true
                this.productLoading = true
                this.productError = null
                this.photosLoading = {error:null,loading:false,progress:0,loaded:0,total:0}
                productService.getProductReportData(this.$route.params.pid, product.id)
                    .then(data => {
                        this.productLoading = false
                        this.selectedItemReports = data
                    })
                    .catch(error => {
                        this.productLoading = false
                        this.productError = error
                    })
            },
            hideDialog(){
                this.selectedItem = null
                this.selectedItemReports = []
                this.selectedItemRPhotos = []
                this.selectedItem = null
                this.dialog = false
                this.productLoading = false
                this.productError = null
            },

            repFileChange(){
                if (this.prereport.photos.length > 0){
                    
                    this.photosLoading = {error:null,loading:true,progress:0,loaded:0,total:0}

                    let formData = new FormData()
                    for (let file of this.prereport.photos) {
                        formData.append("files[]", file);
                    }
                    let callback = (e) => {
                        this.photosLoading.loaded = e.loaded
                        this.photosLoading.total = e.total

                        if (e.lengthComputable) {
                            let percentage = Math.round((e.loaded * 100) / e.total);
                            this.photosLoading.progress = percentage
                        }
                    }
                    fileService.uploadFiles(formData,callback)
                        .then(data => {
                            this.prereport.photos = []
                            this.prereport.tempPhotos = this.prereport.tempPhotos.concat(data)
                            this.photosLoading = {error:null,loading:false,progress:0,loaded:0,total:0}
                        })
                        .catch(error => {
                            this.photosLoading = {error:error,loading:false,progress:0,loaded:0,total:0}
                        })
                }
            },

            sendPrereport(){
                this.prereport.sending = true
                reportService.sendPreReport(this.prereport)
                    .then(data => {
                        this.report = data
                        this.prereport.photos = []
                        this.prereport.tempPhotos = []
                        this.prereport.comment = data.comment
                        this.prereport.reportPhoto = data.reportPhoto
                        this.sheet = false
                        this.prereport.sending = false
                    })
                    .catch(error => {
                        this.prereport.sending = false
                    })
            },

            fchange(){
                if (this.photos.length > 0){
                    
                    this.photosLoading = {error:null,loading:true,progress:0,loaded:0,total:0}

                    let formData = new FormData()
                    for (let file of this.photos) {
                        formData.append("files[]", file);
                    }
                    let callback = (e) => {
                        this.photosLoading.loaded = e.loaded
                        this.photosLoading.total = e.total

                        if (e.lengthComputable) {
                            let percentage = Math.round((e.loaded * 100) / e.total);
                            this.photosLoading.progress = percentage
                        }
                    }
                    fileService.uploadFiles(formData,callback)
                        .then(data => {
                            this.photos = []
                            this.selectedItemRPhotos = data
                            this.photosLoading = {error:null,loading:false,progress:0,loaded:0,total:0}
                        })
                        .catch(error => {
                            this.photosLoading = {error:error,loading:false,progress:0,loaded:0,total:0}
                        })
                }
            },

            validateField(f){
                let rules = [
                    v => !!v || `Обязательное поле`
                ]

                if (f.fieldType.name === "INT") rules.push(v => /^[0-9]{1,}/.test(v) || `Поле должно содержать только целые числа`)
                if (f.fieldType.name === "FLOAT") rules.push(v => /^[0-9]+((,|\.)[0-9]+)?$/.test(v) || `Поле должно содержать только числа`)

                return rules
            },
            submit(){
                if (this.$refs.form.validate()) {
                    let data = {
                        tradePointId: this.$route.params.pid,
                        productId: this.selectedItem.id,
                        reportItemInfo: this.selectedItemReports,
                        photos:this.selectedItemRPhotos,
                        comment: this.selectedItemReports[0].comment
                    }

                    reportService.sendReportData(data)
                        .then(data => {
                            if (!this.report) this.loadReport()
                            this.dialog = false
                        })
                        .catch(error => {
                            this.productLoading = false
                            this.productError = error
                        })
                }
            },
            sendReport(){
                this.sendingReport.title = 'Отправка'
                this.sendingReport.subtitle = 'Проверяем и сохраняем отчёт'
                this.sendingReport.sending = true
                this.sendingReport.dialog = true
                reportService.sendReport(this.report)
                    .then(data => {
                        this.sendingReport.noreportdata = data.noreportdata
                        if (this.sendingReport.noreportdata.length > 0) {
                            this.sendingReport.title = 'Ошибка'
                            this.sendingReport.subtitle = 'Есть незаполненные данные'
                        }

                        this.sendingReport.sending = false
                    })
            }
        }
    }
</script>

<style scoped>
    .emptyspace {height:100px;}
    .photos {height:100px;}
    #repfileslable {
            width: 56px;
            height: 56px;
            display: inline-flex;
            cursor: pointer;
            background: #ff4081;
            border-radius: 50%;
            text-align: center;
            align-items: center;
            justify-content: center;
    }
    #repfileslable + .v-file-input {display:none;}
    #likecb {
        width:30px;
        min-width:30px;
        height:30px;}
</style>